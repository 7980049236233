
















import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarSales from "@/components/sidebar-sales.vue"; // @ is an alias to /src

@Component({
  components: {
    Header,
    SidebarSales,
  },
})
export default class Sales extends Vue {
}
